
class nav {
    static checkConnectivity() {
        if (!navigator.onLine) {
            return true;
        }
        return false;
    }
}

export default nav;
