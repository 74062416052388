import api from "./api";
import auth from "./auth";
import pubsub from "./pubsub";
import emitter from "./emitter";
import constants from "./constants";
import progressUpdater from "./progressUpdater";
import nav from "./navigator";

class utils { }

utils.api = api;
utils.auth = auth;
utils.pubsub = pubsub;
utils.emitter = emitter;
utils.constants = constants;
utils.progressUpdater = progressUpdater;
utils.nav = nav;

export default utils;
