export default {
    //providerTypes
    providerType :{
        psychometrist: 'psychometrist',
        psychologist: 'psychologist',
        BCBA: 'bcba'
    },
    navigator: {
        connectivityError: "You are offline, please check your internet connectivity and try again.",
    }
}